<template>
  <v-data-table
    :headers="computedHeaders"
    :items="data"
    :loading="tableLoading"
    class="elevation-1"
  >
    <template v-slot:item.actions="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" small class="mr-2" @click="edit(item)">
            mdi-pencil
          </v-icon>
        </template>
        <span>Editar</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" small @click="remove(item)"> mdi-delete </v-icon>
        </template>
        <span>Remover</span>
      </v-tooltip>
    </template>
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Metas</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="600px" @click:outside="close">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"
              >Novo</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Metas</span>
            </v-card-title>
            <ValidationObserver ref="form" v-slot="{ passes }">
              <v-form @submit.prevent="passes(save)" width="100%">
                <v-card-text>
                  <v-container>
                    <ValidationProvider
                      v-if="$state.role != 'eqpdtiuo'"
                      vid="uo"
                      name="Unidade Organizacional"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <UOAutoComplete
                        v-model="Item.uo"
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                    <ValidationProvider
                      vid="necessidade"
                      name="Necessidade"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <NecessidadeSelect
                        v-model="Item.necessidade"
                        label="Necessidade"
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                    <ValidationProvider
                      vid="nome"
                      name="Nome"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-text-field
                        v-model="Item.nome"
                        label="Nome"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider
                      vid="descricao"
                      name="Descrição"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-text-field
                        v-model="Item.descricao"
                        label="Descrição"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="darken-1" text @click="close">Cancelar</v-btn>
                  <v-btn :loading="saving" color="darken-1" text @click="save"
                    >Salvar</v-btn
                  >
                </v-card-actions>
              </v-form>
            </ValidationObserver>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
  </v-data-table>
</template>

<script>
import { Meta } from "@/api/eqpdti";
import UOAutoComplete from "@/components/UOAutoComplete";
import NecessidadeSelect from "@/components/NecessidadeSelect";
export default {
  name: "Meta",
  components: {
    UOAutoComplete,
    NecessidadeSelect,
  },
  data() {
    return {
      dialog: false,

      saving: false,
      tableLoading: false,
      errors: [],
      search: null,
      uos: [],
      uoList: [],
      data: [],
      Item: {},
      headers: [
        {
          text: "ID",
          value: "meta_id",
          align: "center",
        },
        {
          text: "Nome",
          value: "nome",
          align: "left",
        },
        {
          text: "Necessidade",
          value: "necessidade_nome",
          align: "left",
        },
        {
          text: "Ações",
          value: "actions",
          sortable: false,
          align: "end",
        },
      ],
    };
  },
  computed: {
    computedHeaders() {
      this.fetch();
      if (this.$state.role != "eqpdtiuo") {
        return [
          {
            text: "Unidade Organizacional",
            value: "uo_sigla",
            align: "center",
          },
        ].concat(this.headers);
      }
      return this.headers;
    },
  },
  methods: {
    remove(item) {
      if (window.confirm("Deseja realmente excluir?")) {
      this.tableLoading = true;
      Meta.remove(item["id"]).then(() => {
        this.tableLoading = false;
        this.fetch();
      });
      }
    },
    edit(item) {
      this.Item = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$refs.form.reset();
      this.$nextTick(() => {
        this.Item = {};
      });
    },
    save() {
      this.$refs.form.validate().then((valid) => {
        if (valid) {
          this.saving = true;
          const _then = () => {
            this.saving = false;
            this.close();
            this.fetch();
          };
          const _catch = (error) => {
            this.saving = false;
            this.errors = error.response.data;
            if (this.errors["non_field_errors"]) {
              this.$emitter.emit(
                "alert:error",
                this.errors["non_field_errors"][0]
              );
            } else {
              this.$emitter.emit("alert:error", this.errors);
            }
          };
          if (this.Item["id"]) {
            let id = this.Item["id"];

            Meta.update(id, this.Item).then(_then).catch(_catch);
          } else {
            this.Item = Object.assign(this.Item, {});
            Meta.post(this.Item).then(_then).catch(_catch);
          }
        }
      });
    },
    fetch() {
      this.tableLoading = true;
      Meta.list({}).then((response) => {
        this.tableLoading = false;
        this.data = response.data;
      });
    },
  },
  // created() {
  //   this.fetch();
  // },
};
</script>
